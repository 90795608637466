<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="업무 작성">

        </CarrotTitle>
        <div class="board">
          <taskPopup v-if="add.showApprovalLine" @close="add.showApprovalLine=false"  @selected="add.setStaff"></taskPopup>
          <taskCCPopup v-if="add.showApprovalLineCC" @close="add.showApprovalLineCC=false"  @selected="add.setStaffCC"></taskCCPopup>
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td colspan="3">
                                    <input type="text" v-model.trim="add.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 업무처리자</th>
                              <td colspan="3">
                                <button class="btn-default ml-10 float-left" @click="add.showApprovalLine=true">선택</button>
                                <span class="ml-10 float-left" v-for="s in add.line_staff" :key="s.idx">{{s.ename}}({{s.kname}})
                                  <button class="btn-del" @click="add.delLineStaff(s.num)">X</button>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 업무공유자</th>
                              <td colspan="3">
                                <button class="btn-default ml-10 float-left" @click="add.showApprovalLineCC=true">선택</button>
                                <span class="ml-10 float-left" v-for="s in add.cc_staff" :key="s.idx">{{s.ename}}({{s.kname}})
                                  <button class="btn-del" @click="add.delCCStaff(s.num)">X</button>
                                </span>
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 중요도</th>
                                <td>
                                  <select v-model="add.priority" class="w-200px">
                                    <option value="낮음">낮음</option>
                                    <option value="보통">보통</option>
                                    <option value="높음">높음</option>
                                  </select>
                                </td>
                                <th><span class="txt-red">*</span> 진행상태</th>
                                <td>
                                  <select v-model="add.progress_state" class="w-200px">
                                    <option value="업무요청">업무요청</option>
                                    <option value="진행중">진행중</option>
                                    <option value="지연">지연</option>
                                    <option value="보류">보류</option>
                                    <option value="완료">완료</option>
                                  </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td colspan="3">
                                    <CarrotEditor etype="managementplan" v-model="add.contents" class="w-100per h-300px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부</th>
                                <td colspan="3">
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file2" class="mt-5"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotEditor from '@/components/common/CarrotEditor.vue'
import Swal from 'sweetalert2'
import taskPopup from '@/components/popup/payment/taskPopup.vue'
import taskCCPopup from '@/components/popup/payment/taskCCPopup.vue'
export default {
    layout:"BusinessManagement",
    components: {
        CarrotEditor,
      taskPopup,
      taskCCPopup
    },
    setup() {
        const router = useRouter();
        const toast = useToast();

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const add = reactive({
            title : "",
            ctype : "",
            complete_date : "",
            priority      : "보통",
            contents      : "",
            progress_state   : "업무요청",
            showApprovalLine: false,
            showApprovalLineCC: false,
            line_staff : [],
            cc_staff : [],
            doCancel: () => {
                router.go(-1);
            },
            delLineStaff: (idx) => {
              add.line_staff.splice(idx, 1);
            },
            delCCStaff: (idx) => {
              add.cc_staff.splice(idx, 1);
            },
            getPreset: () => {
                let params = {
                    ctype : add.ctype
                };
                axios.get("/rest/businessmgr/managementPlanPreset", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.contents = res.data.preset;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit: () => {
                var formData = new FormData();
                formData.append("title", add.title);
                formData.append("priority"     , add.priority);
                formData.append("progress_state"     , add.progress_state);
                formData.append("contents"     , add.contents);
                formData.append("line_staff", JSON.stringify(add.line_staff));
                formData.append("cc_staff", JSON.stringify(add.cc_staff));
                if(file1.value.file.filedata!==null) {
                    formData.append("nfile1[]", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile2[]", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile3[]", file3.value.file.filedata);
                }

                if( !add.title ){
                    console.log(add);
                    Swal.fire({
                        title : "업무요청",
                        text  : "필수 항목을 입력해주세요."
                    });
                    return;
                }

                axios.post('/rest/task/taskAdd', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"TaskManagement-TaskView-idx",
                            params : { 
                                idx:res.data.idx 
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
            setStaff : (info) => {
              add.showApprovalLine = false;
              add.line_staff = [];
              for(let i=0;i<info.line_staff.length;i++) {
                   info.line_staff[i]["num"]=i;
                  add.line_staff.push(info.line_staff[i]);
              }
            },
            setStaffCC : (info) => {
              add.showApprovalLineCC = false;
              add.cc_staff = [];
              for(let i=0;i<info.line_staff.length;i++) {
                info.line_staff[i]["num"]=i;
                add.cc_staff.push(info.line_staff[i]);
              }
            },
        });

        onMounted(() => {
            // Mounted
        });

        return {add, file1, file2, file3};
    }
}
</script>

<style lang="scss" scoped>
</style>